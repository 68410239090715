/*
 * Reducer actions related with login
 */
import * as types from '../types'

export function requestLogin(params, successCb, errorCb) {
  return {
    type: types.LOGIN_REQUEST,
    params,
    successCb,
    errorCb,
  }
}

export function loginFailed() {
  return {
    type: types.LOGIN_FAILED,
  }
}

export function onLoginResponse(response, token) {
  return {
    type: types.LOGIN_RESPONSE,
    response,
    token,
  }
}

export function onLoginUserData(response) {
  return {
    type: types.LOGIN_USERDATA,
    response,
  }
}
export function logOut() {
  return {
    type: types.LOG_OUT,
  }
}
export function requestSignUp(params, successCb, errorCb) {
  return {
    type: types.SIGNUP_REQUEST,
    params,
    successCb,
    errorCb,
  }
}
export function requestSignUpVerifyOTP(params, successCb, errorCb) {
  return {
    type: types.SIGNUP_VERIFY_OTP_REQUEST,
    params,
    successCb,
    errorCb,
  }
}
