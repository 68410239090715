// loaders
export const ENABLE_LOADER = 'ENABLE_LOADER'
export const DISABLE_LOADER = 'DISABLE_LOADER'
///App
export const SET_ENABLE_FIXED_HEADER = 'SET_ENABLE_HEADER_SHADOW'
export const SET_ENABLE_HEADER_SHADOW = 'SET_ENABLE_HEADER_SHADOW'
export const SET_SIDEBAR_SHOW = 'SET_SIDEBAR_SHOW'
export const SET_UN_FOLDABLE = 'SET_UN_FOLDABLE'
//fcm token
export const SET_FCM_TOKEN = 'SET_FCM_TOKEN'

// login
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE'
export const LOGIN_USERDATA = 'LOGIN_USERDATA'
export const LOGIN_LOADING_ENDED = 'LOGIN_LOADING_ENDED'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOG_OUT = 'LOG_OUT'
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_VERIFY_OTP_REQUEST = 'SIGNUP_VERIFY_OTP_REQUEST'

export const GET_HOME_DASHBOARD = 'GET_HOME_DASHBOARD'
export const SET_HOME_DASHBOARD = 'SET_HOME_DASHBOARD'
//profile
export const GET_PROFILE = 'GET_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const PROFILE_RESPONSE = 'PROFILE_RESPONSE'
export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE'
export const UPDATE_NOTIFICATION_COUNT = 'UPDATE_NOTIFICATION_COUNT'
//Master Home
export const GET_ITEMS_LIST = 'GET_ITEMS_LIST'
export const SET_ITEMS_LIST_RESPONSE = 'SET_ITEMS_LIST_RESPONSE'
export const GET_CUSTOMER_LIST = 'GET_CUSTOMER_LIST'
export const SET_CUSTOMER_LIST_RESPONSE = 'SET_CUSTOMER_LIST_RESPONSE'
