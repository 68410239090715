import { compose, applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistCombineReducers } from 'redux-persist'
import storageSession from 'redux-persist/es/storage/session'
import persistRoutes from './persistRoutes'
import rootReducers from './reducers'
import rootSaga from './sagas'

const config = {
  key: 'root',
  storage: storageSession,
  whitelist: persistRoutes,
}

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV === `development`) {
  // const { logger } = require(`redux-logger`)
  // middlewares.push(logger)
}

const reducers = persistCombineReducers(config, rootReducers)
const enhancers = [applyMiddleware(...middlewares)]
const store = createStore(reducers, undefined, compose(...enhancers))
const persistor = persistStore(store, null, () => {
  //   console.log('Test', store.getState())
})
const configureStore = () => {
  return { persistor, store }
}

sagaMiddleware.run(rootSaga)

export default configureStore
