import { put, call } from 'redux-saga/effects'
import { loginUser } from '../../../api/authApi'
import { takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/types'
import { loginActions } from '../../actions'
// Our worker Saga that logins the user
function* loginAsync(action) {
  const { params, successCb, errorCb } = action
  try {
    const response = yield call(loginUser, action.params)
    console.log(response)
    if (response.status === 200 && response?.data?.status) {
      yield put(loginActions.onLoginResponse(response?.data?.data, response?.data?.token))
      console.log(response?.data?.data)
      successCb(response?.data?.data)
    } else {
      yield put(loginActions.loginFailed())
      setTimeout(() => {
        console.log(response)
        errorCb(response?.data?.data)
      }, 200)
    }
  } catch (error) {
    yield put(loginActions.loginFailed())
  } finally {
  }
}
export default function* watchLogin() {
  yield takeLatest(types.LOGIN_REQUEST, loginAsync)
}
