import apiClient from '../../helpers/apiClient'
const loginUrl = `/api/v1/Login`
const signUpUrl = '/api/v1/SelUser/SignUp'
const verifyOTPRegisterUrl = '/api/v1/SelUser/VerifyOTPRegister'
export const loginUser = async (params) => {
  try {
    return await apiClient.post(loginUrl, params)
  } catch (error) {
    console.log(error)
  }
}

export const signUpAPI = async (params) => {
  try {
    return await apiClient.post(signUpUrl, params)
  } catch (error) {
    console.log(error)
  }
}

export const signUpVerifyOTPAPI = async (params) => {
  try {
    return await apiClient.post(verifyOTPRegisterUrl, params)
  } catch (error) {
    console.log(error)
  }
}
