/* Redux saga class
 * Saga for user profile
 */
import { put, call } from 'redux-saga/effects'
import { getHomeDashboardApi, getUserProfile, updateUserProfile } from '../../../api/profile'
import { appActions, loginActions, profileActions } from 'src/store/actions'
import { takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/types'

function* getProfileAsync(action) {
  try {
    yield put(appActions.enableLoader())
    const response = yield call(getUserProfile, action.params)
    if (response.data?.statusCode === 200 && response?.data?.isSuccess) {
      yield put(profileActions.onProfileResponse(response?.data?.responseData))
      yield put(loginActions.onLoginUserData(response?.data?.responseData))
    } else {
      setTimeout(() => {
        console.log('error')
      }, 200)
    }
  } catch (error) {
    console.log('error', error)
    setTimeout(() => {
      console.log('error')
    }, 200)
  } finally {
    yield put(appActions.disableLoader())
  }
}
function* getGetHomeDashboardAsync(action) {
  try {
    yield put(appActions.enableLoader())
    const response = yield call(getHomeDashboardApi, action.params)
    if (response.data?.statusCode === 200 && response?.data?.isSuccess) {
      yield put(profileActions.setHomeDashboard(response?.data?.responseData))
    } else {
      setTimeout(() => {
        console.log('error')
      }, 200)
    }
  } catch (error) {
    console.log('error', error)
    setTimeout(() => {
      console.log('error')
    }, 200)
  } finally {
    yield put(appActions.disableLoader())
  }
}
function* updateProfileAsync(action) {
  const { params, successCb, errorCb } = action
  try {
    yield put(appActions.enableLoader())
    const response = yield call(updateUserProfile, action.params)
    if (response.data?.statusCode === 200 && response?.data?.isSuccess) {
      successCb(response?.data?.responseData)
    } else {
      setTimeout(() => {
        console.log('error')
      }, 200)
    }
  } catch (error) {
    setTimeout(() => {
      console.log('error')
    }, 200)
  } finally {
    yield put(appActions.disableLoader())
  }
}

export default function* watchProfile() {
  yield takeLatest(types.GET_PROFILE, getProfileAsync)
  yield takeLatest(types.GET_HOME_DASHBOARD, getGetHomeDashboardAsync)
  yield takeLatest(types.UPDATE_PROFILE, updateProfileAsync)
}
