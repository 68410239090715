import * as types from '../../actions/types'

const L_UserToken = JSON.parse(localStorage.getItem('userToken'))
const user = JSON.parse(localStorage.getItem('userData'))
const initialState = L_UserToken
  ? {
      isLoggedIn: true,
      userData: user,
      userToken: L_UserToken,
      fcmToken: '',
    }
  : {
      isLoggedIn: false,
      userData: {},
      userToken: '',
      fcmToken: '',
    }

export const login = function (state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_RESPONSE:
      return {
        ...state,
        userData: action.response,
        userToken: action.token,
        isLoggedIn: true,
      }
    case types.LOGIN_FAILED:
      return {
        ...state,
        userData: {},
        isLoggedIn: false,
      }
    case types.SET_FCM_TOKEN:
      return {
        ...state,
        fcmToken: action.token,
      }
    case types.LOG_OUT:
      return {
        ...state,
        userData: {},
        userToken: '',
        fcmToken: '',
        isLoggedIn: false,
      }
    default:
      return state
  }
}
