/* Profile Reducer
 * handles user profile states in the app
 */
import * as types from '../../actions/types'

const initialState = {
  userProfile: {},
  appVersion: {},
  homeDashboard: {},
}

export const profile = function (state = initialState, action) {
  switch (action.type) {
    case types.PROFILE_RESPONSE:
      return {
        ...state,
        userProfile: action.response,
      }
    case types.SET_HOME_DASHBOARD:
      return {
        ...state,
        homeDashboard: action.response?.objHomeDashboardResp,
        appVersion: action.response?.appVersion,
      }
    case types.UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        userProfile: { ...state.userProfile, tempPhoto: action?.imageUrl || '' },
      }
    case types.UPDATE_NOTIFICATION_COUNT:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          notificationCount: action?.count || 0,
        },
      }

    default:
      return state
  }
}
