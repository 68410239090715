import * as types from '../../actions/types'

const initialState = {
  showLoader: false,
  showLoaderFull: false,
  enableFixedHeader: false,
  enableHeaderShadow: false,
  sidebarShow: true,
  unfoldable: false,
}

export const app = function (state = initialState, action) {
  switch (action.type) {
    case types.ENABLE_LOADER:
      return {
        ...state,
        showLoaderFull: action.showLoaderFull,
        showLoader: true,
      }
    case types.DISABLE_LOADER:
      return {
        ...state,
        showLoader: false,
        showLoaderFull: false,
      }
    case types.SET_ENABLE_FIXED_HEADER:
      return {
        ...state,
        enableFixedHeader: action.enableFixedHeader,
      }
    case types.SET_ENABLE_HEADER_SHADOW:
      return {
        ...state,
        enableHeaderShadow: action.enableHeaderShadow,
      }
    case types.SET_SIDEBAR_SHOW:
      return { ...state, sidebarShow: action.sidebarShow }
    case types.SET_UN_FOLDABLE:
      return { ...state, unfoldable: action.unfoldable }
    default:
      return state
  }
}
