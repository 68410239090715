import apiClient, { apiFormClient } from '../../helpers/apiClient'

const profileUrl = '/api/v1/User/GetUserProfile'
const uploadPictureUrl = `/api/v1/User/UpdateProfilePhoto`
const updateProfile = `/api/v1/User/SaveUpdateUser`
const getUserListUrl = `/api/v1/User/GetUserList`
const GetHomeDashboardUrl = '/api/v1/User/GetHomeDashboard'
const SaveUpdateComppanyUrl = `/api/v1/User/SaveUpdateComppany`
const UpdateCompanyImgUrl = `/api/v1/User/UpdateCompanyImg`
const GetCompanyByIdUrl = `/api/v1/User/GetCompanyById`

export const getUserProfile = async (params) => {
  const getProfileUrl = `${profileUrl}?Id=${params.Id}`
  const response = await apiClient.get(getProfileUrl)
  return response
}
export const uploadUserProfilePhoto = async (id, oldfilePath, formData) => {
  try {
    const response = await apiFormClient.post(
      `${uploadPictureUrl}?Id=${id}&oldfilePath=${oldfilePath}`,
      formData,
    )
    return response
  } catch (error) {}
}
export const updateUserProfile = async (params) => {
  try {
    const response = await apiClient.post(updateProfile, params)
    return response
  } catch (error) {
    return Promise.reject('Network Error!')
  }
}
export const getUserListApi = async (params) => {
  const getProfileUrl = `${getUserListUrl}?CompanyId=${params.CompanyId}&SuperRole=${params.SuperRole}&AccountTypeId=${params.AccountTypeId}&Search=${params.Search}&PageIndex=${params.PageIndex}&PageSize=${params.PageSize}`
  const response = await apiClient.get(getProfileUrl)
  return response
}
export const getHomeDashboardApi = async (params) => {
  const getHomeDashboardUrl = `${GetHomeDashboardUrl}?CompanyId=${params.CompanyId}`
  const response = await apiClient.get(getHomeDashboardUrl)
  return response
}
export const getCompanyByIdApi = async (Id) => {
  const getProfileUrl = `${GetCompanyByIdUrl}?Id=${Id}`
  const response = await apiClient.get(getProfileUrl)
  return response
}
export const updateCompanyImgApi = async (id, oldfilePath, formData) => {
  try {
    const response = await apiFormClient.post(
      `${UpdateCompanyImgUrl}?Id=${id}&oldfilePath=${oldfilePath}`,
      formData,
    )
    return response
  } catch (error) {}
}
export const saveUpdateComppanyApi = async (params) => {
  try {
    const response = await apiClient.post(SaveUpdateComppanyUrl, params)
    return response
  } catch (error) {
    return Promise.reject('Network Error!')
  }
}
