/*
 * Reducer actions related with login
 */
import * as types from '../types'

export function getProfile(params) {
  return {
    type: types.GET_PROFILE,
    params,
  }
}
export function updateProfile(params, successCb, errorCb) {
  return {
    type: types.UPDATE_PROFILE,
    params,
    successCb,
    errorCb,
  }
}
export function onProfileResponse(response) {
  return {
    type: types.PROFILE_RESPONSE,
    response,
  }
}
export function updateProfilePicture(imageUrl) {
  return {
    type: types.UPDATE_PROFILE_PICTURE,
    imageUrl,
  }
}
export function updateNotificationCount(count) {
  return {
    type: types.UPDATE_NOTIFICATION_COUNT,
    count,
  }
}
export function getHomeDashboard(params) {
  return {
    type: types.GET_HOME_DASHBOARD,
    params,
  }
}
export function setHomeDashboard(response) {
  return {
    type: types.SET_HOME_DASHBOARD,
    response,
  }
}
