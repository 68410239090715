/* Profile Reducer
 * handles user profile states in the app
 */
import * as types from '../../actions/types'

const initialState = {
  allItems: [],
  allCustomers: [],
}

export const master = function (state = initialState, action) {
  switch (action.type) {
    case types.SET_ITEMS_LIST_RESPONSE:
      return {
        ...state,
        allItems: action.response,
      }
    case types.SET_CUSTOMER_LIST_RESPONSE:
      return {
        ...state,
        allCustomers: action.response,
      }
    default:
      return state
  }
}
