import axios from 'axios'
import configureStore from '../store/store'

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use(function (config) {
  const { persistor, store } = configureStore()
  const token = store.getState().login?.userToken
  config.timeout = 1000 * 8 // Wait for 8 seconds
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  // console.log('Starting Request', JSON.stringify(config, null, 2))
  return config
})

export const apiFormClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

apiFormClient.interceptors.request.use(function (config) {
  const { persistor, store } = configureStore()
  const token = store.getState().login.userToken
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

export default apiClient
