/*
 * Reducer actions related with login
 */
import * as types from '../types'

export function getItemsList(params, successCb, errorCb) {
  return {
    type: types.GET_ITEMS_LIST,
    params,
    successCb,
    errorCb,
  }
}

export function onItemsListResponse(response) {
  return {
    type: types.SET_ITEMS_LIST_RESPONSE,
    response,
  }
}
export function getCustomerList(params, successCb, errorCb) {
  return {
    type: types.GET_CUSTOMER_LIST,
    params,
    successCb,
    errorCb,
  }
}

export function onCustomersListResponse(response: any) {
  return {
    type: types.SET_CUSTOMER_LIST_RESPONSE,
    response,
  }
}
