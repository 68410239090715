import * as types from '../types'

export function enableLoader(showLoaderFull = false) {
  return {
    type: types.ENABLE_LOADER,
    showLoaderFull: showLoaderFull,
  }
}

export function disableLoader() {
  return {
    type: types.DISABLE_LOADER,
  }
}
export const setEnableFixedHeader = (enableFixedHeader) => ({
  type: types.SET_ENABLE_FIXED_HEADER,
  enableFixedHeader,
})

export const setEnableHeaderShadow = (enableHeaderShadow) => ({
  type: types.SET_ENABLE_HEADER_SHADOW,
  enableHeaderShadow,
})
export const setSidebarShow = (sidebarShow) => ({
  type: types.SET_SIDEBAR_SHOW,
  sidebarShow,
})
export const setUnfoldable = (unfoldable) => ({
  type: types.SET_UN_FOLDABLE,
  unfoldable,
})
