import { put, call } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/types'
import { GetCustomersListApi, getItemsListApi } from '../../../api/masterApi'
import { appActions, masterActions } from 'src/store/actions'

// Our worker Saga that logins the user
function* getGetItemsListAsync(action) {
  const { params, successCb, errorCb } = action
  console.log('adi')
  try {
    yield put(appActions.enableLoader())
    const response = yield call(getItemsListApi, action.params)
    if (response.data?.statusCode === 200 && response?.data?.isSuccess) {
      console.log('response?.data?.responseData ', response?.data?.responseData)
      yield put(masterActions.onItemsListResponse(response?.data?.responseData || []))
      successCb(response?.data?.responseData)
    } else {
      console.log('Error ')
    }
  } catch (error) {
    setTimeout(() => {
      console.log('Error ')
    }, 200)
  } finally {
    yield put(appActions.disableLoader())
  }
}
function* getCustomersListAsync(action) {
  const { params, successCb, errorCb } = action
  try {
    yield put(appActions.enableLoader())
    console.log(action)
    const response = yield call(GetCustomersListApi, action.params)
    if (response.data?.statusCode === 200 && response?.data?.isSuccess) {
      yield put(masterActions.onCustomersListResponse(response?.data?.responseData || []))
      successCb(response?.data?.responseData)
    } else {
      setTimeout(() => {
        console.log('Error ')
      }, 200)
    }
  } catch (error) {
    setTimeout(() => {
      console.log('Error ')
    }, 200)
  } finally {
    yield put(appActions.disableLoader())
  }
}
export default function* watchProfile() {
  yield takeLatest(types.GET_ITEMS_LIST, getGetItemsListAsync)
  yield takeLatest(types.GET_CUSTOMER_LIST, getCustomersListAsync)
}
