import apiClient, { apiFormClient } from '../../helpers/apiClient'

const GetItemsListUrl = `/api/v1/Master/GetItemsList`
const SaveUpdateItemsUrl = `/api/v1/Master/SaveUpdateItems`
const GetItemsByIdUrl = `/api/v1/Master/GetItemsById`
const UpdateItemImgUrl = `/api/v1/Master/UpdateItemImg`

const GetCustomersListUrl = `/api/v1/Master/GetCustomersList`
const SaveUpdateCustomersUrl = `/api/v1/Master/SaveUpdateCustomers`
const GetCustomersByIdUrl = `/api/v1/Master/GetCustomersById`
const UpdateCustomerImgUrl = `/api/v1/Master/UpdateCustomerImg`

/**
 Item Master---------------------------------------- Start
 */
export const getItemsListApi = async (params) => {
  try {
    let apiURL = `${GetItemsListUrl}?CompanyId=${params?.CompanyId}&SuperRole=${params?.SuperRole}&OrderBy=${params?.OrderBy}&Search=${params?.Search}&PageSize=${params?.PageSize}&PageIndex=${params?.PageIndex}`
    const response = await apiClient.get(apiURL)
    return response
  } catch (error) {
    console.log(error)
  }
}
export const SaveUpdateItemsApi = async (params) => {
  try {
    console.log('🚀 ~ SaveUpdateItemsApi url', params)
    const response = await apiClient.post(`${SaveUpdateItemsUrl}`, params)
    console.log('response SaveUpdateItemsApi', response?.data)
    return response
  } catch (error) {
    console.log('Error SaveUpdateItemsApi', error)
  }
}
export const UpdateItemImgApi = async (params, body) => {
  try {
    let url = `${UpdateItemImgUrl}?Id=${params.Id}&oldfilePath=${params.oldfilePath}`
    console.log('🚀 ~ UpdateItemImgUrl', url)
    const response = await apiFormClient.post(url, body)
    console.log('response UpdateItemImgUrl', response?.data)
    return response
  } catch (error) {
    console.log('Error UpdateAddPost', error)
  }
}
export const GetItemsByIdApi = async (Id) => {
  try {
    let apiURL = `${GetItemsByIdUrl}?Id=${Id}`
    const response = await apiClient.get(apiURL)
    return response
  } catch (error) {
    console.log('Error', error)
  }
}
/**
 Item Master---------------------------------------- End
 */
/**
 Customer Master---------------------------------------- Start
 */
export const GetCustomersListApi = async (params) => {
  try {
    let apiURL = `${GetCustomersListUrl}?CompanyId=${params.CompanyId}&SuperRole=${params.SuperRole}&OrderBy=${params.OrderBy}&Search=${params.Search}&PageSize=${params.PageSize}&PageIndex=${params.PageIndex}`
    const response = await apiClient.get(apiURL)
    return response
  } catch (error) {
    console.log(error)
  }
}
export const SaveUpdateCustomersApi = async (params) => {
  try {
    console.log('🚀 ~ SaveUpdateCustomersUrl url', params)
    const response = await apiClient.post(`${SaveUpdateCustomersUrl}`, params)
    console.log('response SaveUpdateCustomersUrl', response?.data)
    return response
  } catch (error) {
    console.log('Error SaveUpdateCustomersUrl', error)
  }
}

export const GetCustomersByIdApi = async (Id) => {
  try {
    let apiURL = `${GetCustomersByIdUrl}?Id=${Id}`
    console.log('apiURL', apiURL)
    const response = await apiClient.get(apiURL)
    return response
  } catch (error) {
    console.log('Error', error)
  }
}
export const UpdateCustomerImgApi = async (params, body) => {
  try {
    let url = `${UpdateCustomerImgUrl}?Id=${params.Id}&oldfilePath=${params.oldfilePath}`
    console.log('🚀 ~ UpdateItemImgUrl', url)
    const response = await apiFormClient.post(url, body)
    console.log('response UpdateCustomerImgUrl', response?.data)
    return response
  } catch (error) {
    console.log('Error UpdateCustomerImgUrl', error)
  }
}
/**
 Customer Master---------------------------------------- End
 */
